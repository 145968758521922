@import '../../assets/styles/mixin';

header {
    width: 100%;
    position: relative;
    z-index: 99;

    @include header {
        z-index: 100
    }

    @include header2 {
        padding: 10px;
    }

    .logo {
        transition: .5s all;
        width: 400px;
        position: relative;
        z-index: 100;

        @include header {
            width: 350px;
        }

        @include header2 {
            width: 250px;
        }

        &:hover {
            transform: scale(1.1);
        }

        img {
            width: 100%
        }
    }

    nav {
        width: 100%;
        padding: 30px;
        justify-content: space-between;
        transition: .5s all;

        @include header {
            justify-content: space-around;
        }

        @include header2 {
            justify-content: space-between;
        }


        .link {
            display: inline;
            color: var(--textColor);
        }


        .visible {
            right: -10%;
            display: flex;
        }

        ul {
            display: flex;
            gap: 3rem;

            @include header {
                display: none;

                &.visible {
                    display: flex;
                    backdrop-filter: blur(10px);
                    background-attachment: fixed;
                    background-size: cover;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    position: fixed;
                    gap: 1rem;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    padding-top: 50px;
                    height: max-content;
                    width: 80%;
                    border-radius: 0.5rem;
                    border: 1px solid;
                    border-image: linear-gradient(90deg, #ec9659 0%, #f481d4 50%, #d48af2 100%);
                    animation: apparition .7s ease-in
                }
            }

            a {
                background: linear-gradient(to left, #ff6a00 0%, #f828bd 50%, #dd00ff 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
                transition: .5s all;
                padding: 5px;
                text-shadow: none;
                font-size: 1.5rem;
                filter: drop-shadow(0 0 5px white) drop-shadow(0 0 10px white);

                &:hover {
                    transform: scale(1.15);
                }
            }

            i {
                text-shadow: none;
                font-size: 1.5rem;
                transition: .5s all;
                background: linear-gradient(to left, #ff6a00 0%, rgb(248, 40, 189)50%, #dd00ff 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;

                &:hover {
                    transform: scale(1.15);
                }
            }
        }

        ;

        .menuBurger {
            display: none;
            width: 30px;
            height: 60px;

            @include header {
                font-weight: 700;
                font-size: 1.2rem;
                cursor: pointer;
                transition: .5s all;
                display: block;

                button {
                    width: 100%;
                    height: 100%;
                    position: relative;
                    background-color: transparent;
                    border: 0;
                    cursor: pointer;
                    z-index: 100;
                }

                span {
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 5px;
                    width: 100%;
                    box-shadow: 0px 0px 5px white, 0px 0px 10px white;
                    background: linear-gradient(90deg, rgba(236, 150, 89, 1) 0%, rgb(244, 129, 212) 50%, rgba(212, 138, 242, 1) 100%);
                    border-radius: 5px;
                }

                .topLine {
                    top: 20px;
                    transition: .5s ease-in-out
                }


                .middleLine {
                    transition: .5s ease-in-out
                }

                .bottomLine {
                    top: 40px;
                    transition: .5s ease-in-out
                }


                .actived {
                    .topLine {
                        transform: translate(-50%, -50%) rotate(135deg);
                        top: 50%;
                    }

                    .middleLine {
                        transform: translate(-50%, -50%) rotate(45deg);
                    }

                    .bottomLine {
                        transform: translate(-50%, -50%) rotate(135deg);
                        top: 50%;
                    }

                }
            }
        }
    }
}

.container {
    display: none;
    transition: opacity ease-in 1s;
    margin-top: 50px;
    width: 100%;
    position: relative;
    gap: 4rem;
    align-items: center;
    justify-content: center;

    @include header {
        display: none;

        &.visible {
            display: flex;
            flex-direction: column;
            backdrop-filter: blur(5px);
            position: fixed;
            top: -10%;
            left: 0%;
            height: 106vh;
            z-index: 50;
            padding-top: 15vh;
            animation: apparition .7s ease-in;

            a {
                font-family: "Sunny";
            }

            @include header {
                a {
                    font-size: 1.8rem;
                }
            }
        }
    }
}


@keyframes float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes apparition {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.boxBubble,
.boxBubble2,
.boxBubble3,
.boxBubble4 {
    animation: float 3s ease-in-out infinite;

    @include header {
        animation: float 5s ease-in-out infinite;
    }

    cursor: pointer;

    &:hover {
        animation-play-state: paused;

        @include header {
            animation-play-state: running;
        }
    }

    img {
        z-index: -1;

        &.firstBubble {
            width: 150px;
            position: absolute;
            transform: rotate(0.9turn);
            left: -30px;
            top: -50px;

            @include header {
                width: 100px;
            }
        }

        &.secondBubble {
            width: 100px;
            transform: rotate(0.9turn);
            position: absolute;
            right: -30px;
            top: -25px;
        }

        &.thirdBubble {
            width: 100px;
            transform: rotate(0.65turn);
            position: absolute;
            right: -40px;
        }

        &.fourthBubble {
            width: 200px;
            transform: rotate(0.70turn);
            position: absolute;
            right: 0;
            transform: rotate(0.4turn);
            bottom: -50px;

            @include header {
                bottom: -30px;
                width: 120px;
            }
        }

        &.fifthBubble {
            width: 100px;
            transform: rotate(0.65turn);
            position: absolute;
            right: -40px;

            @include header {
                right: -30px;
            }
        }

        &.sixthBubble {
            width: 150px;
            transform: rotate(0.65turn);
            position: absolute;
            right: -35px;

            @include header {
                width: 80px;
                right: -15px;
            }
        }


    }
}

.boxBubble {
    align-self: center;
}

.boxBubble2 {
    align-self: flex-end;
    z-index: 2;

    @include header {
        align-self: center;
    }
}

.boxBubble3 {
    position: relative;
    left: 50px;

    @include header {
        left: 0;
    }
}

.boxBubble4 {
    position: relative;
    top: 20px;
    z-index: 1;

    @include header {
        top: 0;
    }
}

.bulles1 {
    background: linear-gradient(to right, #ff852e 0%, #f64fc7 50%, #e33dfd 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
    filter: drop-shadow(0 0 5px white) drop-shadow(0 0 10px white);

}

.bulles2 {
    background: linear-gradient(to left, #ff852e 0%, #f64fc7 50%, #e33dfd 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
    filter: drop-shadow(0 0 5px white) drop-shadow(0 0 10px white);
}

.bulles3 {
    background: linear-gradient(to right, #ff852e 0%, #f64fc7 50%, #e33dfd 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
    filter: drop-shadow(0 0 5px white) drop-shadow(0 0 10px white);
}

.bulles4 {
    background: linear-gradient(to left, #ff852e 0%, #f64fc7 50%, #e33dfd 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
    filter: drop-shadow(0 0 5px white) drop-shadow(0 0 10px white);

}

.bulles5 {
    background: linear-gradient(to right, #ff852e 0%, #f64fc7 50%, #e33dfd 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: none;
    filter: drop-shadow(0 0 5px white) drop-shadow(0 0 10px white);

    img {
        width: 80px;

        @include header {
            width: 50px;
        }
    }
}