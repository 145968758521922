.df{
    display: flex;
}

.dn{
    display: none;
}

.dib{
    display: inline-block;
}

.jcc{
    justify-content: center;
}

.jcsa{
    justify-content: space-around;
}

.jcsb{
    justify-content: space-between;
}

.jcs{
    justify-content: start;
}

.jce{
    justify-content:flex-end;
}

.aic{
    align-items: center;
}

.gap1{
    gap: 1rem;
}

.gap2{
    gap: 2rem;
}

.gap3{
    gap: 3rem;
}
.gap5{
    gap: 5rem;
}

.fdr{
    flex-direction: row;
}

.fdc{
    flex-direction: column;
}

.fdrr{
    flex-direction: row-reverse;
}

.jce{
    justify-content: end;
}

.ais{
    align-items: start;
}

.aie{
    align-items: end;
}

.fw{
    flex-wrap: wrap;
}