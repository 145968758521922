@import '../../assets/styles/mixin';


* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    box-sizing: border-box;
    font-family: "Comfortaa";
    font-weight: 700;
    text-shadow: 0px 0px 5px white, 0px 0px 10px white;
}

body {
    overflow-x: hidden;
    position: relative;
    display: flex;
}

ul {
    list-style: none;
}

a,
img {
    display: block;
}

main {
    animation: apparition 1s ease-in-out;
    min-height: 60vh;
    width: 80%;
    margin: 30px auto;
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    @include header {
        z-index: 99;
    }
}

.background {
    display: flex;
    flex-direction: column;
    background-image: url("../images/bcg.webp");
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-repeat: no-repeat;

    @include header {
        background-attachment: scroll;
    }
}

@keyframes apparition {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}