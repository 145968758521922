@mixin xs {
    @media (max-width: 480px) {
        @content;
    }
}

@mixin xm {
    @media (min-width: 576px) {
        @content;
    }
}

@mixin sm {
    @media (min-width: 577px) and (max-width: 768px) {
        @content;
    }
}

@mixin sm-lt {
    @media (max-width: 720px) {
        @content;
    }
}

@mixin md {
    @media (min-width: 769px) and (max-width: 992px) {
        @content;
    }
}

@mixin md-lt {
    @media (max-width: 920px) {
        @content;
    }
}

@mixin lg {
    @media (min-width: 993px) and (max-width: 1200px) {
        @content;
    }
}

@mixin lg-lt {
    @media (max-width: 1200px) {
        @content;
    }
}

@mixin xl {
    @media (min-width: 1201px) {
        @content;
    }
}

@mixin header {
    @media(max-width: 1270px) {
        @content;
    }
}

@mixin header2 {
    @media (max-width: 500px) {
        @content;
    }
}

@mixin footer {
    @media (max-width: 900px) {
        @content;
    }
}

@mixin bubble {
    @media (max-width: 1000px) {
        @content;
    }
}

@mixin bubble2 {
    @media (max-width: 600px) {
        @content;
    }
}

@mixin burger {
    @media (min-width:1000px) {
        @content
    }
}

@mixin sirene {
    @media(max-width:1300px) {
        @content
    }
}

@mixin errorPage{
    @media(max-width:900px){
        @content
    }
}