.b1 {
  background-color: teal;
  border: 1px solid black;
}

.b2 {
  background-color: red;
  border: 1px solid black;
}

.b3 {
  background-color: darkgray;
  border: 1px solid black;
}

.b4 {
  background-color: blue;
  border: 1px solid black;
}
