// PADDING

.p5 {
    padding: 5px;
}

.p10 {
    padding: 10px;
}

.p20 {
    padding: 20px;
}

.p30 {
    padding: 30px;
}

// MARGIN

.m5 {
    margin: 5px;
}

.m10 {
    margin: 10px;
}

.m20{
    margin: 20px;
}

.m30{
    margin: 30px;
}

.mr5 {
    margin-right: 5px;
}

.mr10 {
    margin-right: 10px;
}

.mr20 {
    margin-right: 20px;
}
.mr30 {
    margin-right: 30px;
}

.ml5{
    margin-left: 5px;
}

.ml10 {
    margin-left: 10px;
}

.ml20 {
    margin-left: 20px;
}

.ml30{
    margin-left: 30px;
}

.mb5{
    margin-bottom: 5px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb30{
    margin-bottom: 30px;
}


.mr5{
    margin-right: 5px;
}

.mr10{
    margin-right: 10px;
}

.mr20 {
    margin-right: 20px;
}

.mr30{
    margin-right: 30px;
}

.mt5{
    margin-top: 5px;
}

.mt10{
    margin-top: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mt30 {
    margin-top: 30px;
}

.my10{
    margin-top: 10px;
    margin-bottom: 10px;
}

.my20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.my30 {
    margin-top: 30px;
    margin-bottom: 30px;
}

.my50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.mx10{
    margin-left: 10px;
    margin-right: 10px;
}

.mx20{
    margin-left: 20px;
    margin-right: 20px;
}

.mx30{
    margin-left: 30px;
    margin-right: 30px;
}

// DIVERS

.cp{
    cursor: pointer;
}

.tac{
    text-align: center;
}

.fw700{
    font-weight: 700;
}

.fw900{
    font-weight: 900;
}
