@import '../../assets/styles/mixin';


// POLICE

@font-face {
    font-family: 'neon';
    src: url('../polices/neon2.ttf') format('opentype');
    font-display: swap;

}

@font-face {
    font-family: "sunny";
    src: url("../polices/Sunny.ttf");
    font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "neon";
    color: var(--titleColor);
    line-height: 1.2;
}

h2 {
    font-size: 2rem;
}

.firstTextColor {
    color: var(--textColor);
}

.secondTextColor {
    color: var(--titleColor);
}

.fontNeon {
    font-family: "neon";
    line-height: 1.2;
}

.fsz2 {
    font-size: 1.5rem;
}

// INPUT

input,
textarea {
    border-radius: .5rem;
    outline: 0;
    padding: .5rem 0;
    text-align: center;
    border: 2px solid var(--titleColor);
    caret-color: var(--titleColor);
    color: var(--textColor);
    background-color: transparent;
    text-shadow: none;
    width: 250px;

    &:-webkit-autofill {
        -webkit-background-clip: text;
        -webkit-text-fill-color: var(--textColor);
    }

    &[type="email"]::placeholder {
        color: var(--textColor);
    }
}

input[type="file"] {
    padding: 0.5rem;
}

input[type="checkbox"] {
    width: initial;
}

// Pages catégories

.mainCategories {
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
    width: 80%;
    position: relative;

    h1 {
        margin: 30px 0;
        color: var(--titleColor);
        font-size: 3rem;
    }

    .img {
        max-height: 300px;
        object-fit: contain;
        cursor: pointer;
        transition: .5s all;
        border-radius: .5rem;

        &.modal {
            width: initial;
        }
    }
}

.containerImages {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 5px;

    img {
        transition: .5s all;

        &:hover {
            transform: scale(1.05);
        }
    }
}

.modalContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .modal {
        box-shadow: var(--textColor) 0px 0px 5px, var(--textColor) 0px 0px 10px, var(--textColor) 0px 0px 15px;
        width: 100%;
        cursor: initial;
        max-width: 90vw;
        max-height: 80vh;
        min-width: 300px;
        border-radius: .5rem;
    }

    .close {
        position: absolute;
        color: var(--textColor);
        top: 5px;
        right: 5px;
        font-size: 2rem;
        z-index: 50;
        width: 3rem;
        height: 3rem;
        transition: .5s all;

        &:hover {
            transform: scale(1.2);
        }
    }
}


//Page Evenements

.red {
    color: red;
}

// Button

.button {
    padding: 0.5rem;
    background-color: transparent;
    border: 1px solid var(--titleColor);
    color: var(--textColor);
    font-size: 1rem;
    border-radius: .5rem;
    cursor: pointer;
    transition: .5s all;
    box-shadow: var(--textColor) 0px 0px 5px, var(--textColor) 0px 0px 10px, var(--textColor) 0px 0px 15px;

    &:hover {
        transform: scale(1.1);
    }
}

.button2 {
    padding: 0.5rem;
    background-color: transparent;
    border: 0;
    color: var(--textColor);
    font-size: 1rem;
    border-radius: .5rem;
    cursor: pointer;
    transition: .5s all;

    &:hover {
        box-shadow: var(--textColor) 0px 0px 5px, var(--textColor) 0px 0px 10px, var(--textColor) 0px 0px 15px, var(--yellow) 0px 0px 20px;
    }
}

// ANIMATION BUBBLE BACKGROUND

.bub {
    width: 5%;
    aspect-ratio: 1;
    border: 1px solid white;
    box-shadow: inset 5px -5px 10px white;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, .01);
    backdrop-filter: blur(2px);
    position: absolute;
    bottom: 0;
    z-index: 5;
    will-change: transform, opacity;

    @include bubble {
        width: 10%;
    }

    @include bubble2 {
        width: 15%;
    }

    &::before {
        content: "";
        position: absolute;
        top: 20%;
        left: 20%;
        width: 15%;
        aspect-ratio: 1;
        border-radius: 50%;
        background: white;
        z-index: 10;
        filter: blur(2px);
    }

    &::after {
        content: "";
        position: absolute;
        top: 35%;
        left: 35%;
        width: 10%;
        aspect-ratio: 1;
        border-radius: 50%;
        background: #ffffff;
        z-index: 10;
        filter: blur(2px);
    }

    span {
        position: absolute;
        border-radius: 50%;

        &:nth-child(1) {
            inset: 10px;
            border-left: 15px solid #0fb4ff;
            filter: blur(8px);
        }

        &:nth-child(2) {
            inset: 10px;
            border-right: 15px solid #ff4484;
            filter: blur(8px);
        }

        &:nth-child(3) {
            inset: 20px;
            border-top: 15px solid #ffeb3b;
            filter: blur(8px);
        }

        &:nth-child(4) {
            inset: 30px;
            border-left: 15px solid #ff4484;
            filter: blur(12px);
        }

        &:nth-child(5) {
            inset: 10px;
            border-bottom: 10px solid #ffffff;
            filter: blur(8px);
            transform: rotate(330deg);
        }
    }

}

.bub.a {
    left: 10%;
    animation: bubble2 20s ease-in 1s infinite;
}

.bub.b {
    left: 20%;
    animation: bubble 10s ease-in 1.4s infinite;
}

.bub.c {
    left: 28%;
    animation: bubble 20s ease-in 3.8s infinite;
}

.bub.d {
    left: 40%;
    animation: bubble 16s ease-in .5s infinite;
}

.bub.e {
    left: 75%;
    animation: bubble2 20s ease-in 2.5s infinite;

    @include bubble {
        display: none;
    }
}

.bub.f {
    left: 90%;
    animation: bubble 16s ease-in 3s infinite;

    @include bubble {
        display: none;
    }
}

.bub.g {
    left: 60%;
    animation: bubble 20s ease-in 2s infinite;
}

.bub.k {
    left: 50%;
    animation: bubble 16s ease-in 2s infinite;
}

.bub.i {
    left: 65%;
    animation: bubble2 12s ease-in 2.1s infinite;
}

.bub.j {
    left: 3%;
    animation: bubble 16s ease-in 1.5s infinite;
}

.bub.h {
    left: 35%;
    animation: bubble2 20s ease-in infinite;
}

@keyframes bubble {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    93% {
        opacity: 0;
    }

    99% {
        opacity: 0;
    }

    100% {
        transform: translate(90px, -500vh) rotate(2turn);
    }
}

@keyframes bubble2 {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    93% {
        opacity: 0;
    }

    100% {
        transform: translate(90px, -500vh) rotate(540deg);
    }
}

// PAGE FAQ

details {
    text-align: center;

    summary {
        color: var(--textColor);
        cursor: pointer;

        span {
            color: var(--titleColor);
        }
    }

    p {
        color: var(--titleColor);
        max-width: 600px;
    }
}

.underline {
    text-decoration: underline;
}