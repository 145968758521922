@import '../../assets/styles/mixin';

.footer {
    margin: 0 auto;
    width: 100vw;
    position: relative;
    z-index: 10;
    padding-bottom: 1rem;

    .section {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;

        h2 {
            filter: drop-shadow(0 0 5px white) drop-shadow(0 0 10px white);
            text-shadow: none;
            background: linear-gradient(to right, #ff6a00 0%, #f828bd 50%, #dd00ff 100%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        @include footer {
            flex-direction: column;
            gap: 2rem;

            .h2 {
                @include footer {
                    font-size: 1.5rem;
                    padding: .3rem;
                    width: max-content;
                    margin: 0 auto 1rem;
                }
            }
        }
    }
}

.iFacebook {
    font-size: 1.8rem;
    font-weight: 900;
}

.iInstagram {
    font-size: 2rem;
    font-weight: 900;
}

.reseaux {
    transition: .5s all;
    cursor: pointer;
    color: var(--textColor);
    display: flex;

    &:hover {
        color: var(--titleColor);
    }


}

.email {
    transition: .5s all;

    &:hover {
        transform: scale(1.1);
    }
}

.copyright {
    width: 100%;
}

.link {
    transition: .5s all;
    color: var(--titleColor);
    display: inline-block;

    &:hover {
        transform: scale(1.1);
    }
}